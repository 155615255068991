<template>
  <div id="app">
    <Navbar />
    <router-view/>
    <!-- <AIPerson /> -->
  </div>
</template>
<script>
import Navbar from '@/components/Navbar'
// import AIPerson from '@/components/AIPerson.vue'

export default {
  components: {
    Navbar,
    // AIPerson
  },
  mounted(){
    this.loadScript("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6653f3c0ba64b2e2e20a40be");
    //this.loadScript("https://zqinghui.oss-cn-zhangjiakou.aliyuncs.com/test/guanwang/indexdb/webgl2.loader.js");
    this.loadScript("https://zqinghui.oss-cn-zhangjiakou.aliyuncs.com/test/guanwang/webflow.js");
  },
  methods: {
    loadScript(src) {
      const script = document.createElement('script');
      script.src = src;
      script.async = true; // 可选：异步加载
      script.onload = () => {
        console.log('Script loaded successfully!');
        // 在这里可以执行加载后需要的逻辑
      };
      script.onerror = () => {
        console.error('Script failed to load.');
      };
      document.head.appendChild(script);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
